@import "~@/styles/variables/variables.scss";





















































































































































































.command-form {
	padding: 0 25px 0 23px;
	width: 100%;
	height: 100%;
	overflow: auto;
	box-sizing: border-box;
	::v-deep {
		.el-form {
			.el-form-item {
				margin-bottom: 22px !important;
				.el-form-item__label {
					height: auto;
					line-height: 21px;
					word-break: break-all;
				}
				.command-name {
					padding-left: 11px;
					height: 32px;
					background: #f7f7f7;
					border-radius: 4px;
					font-size: 14px;
					color: #999999;
					line-height: 32px;
				}
			}
			.el-form-item.radio-item {
				.el-form-item__content {
					margin-top: 0;
				}
				.el-radio-group {
					display: flex;
					flex-wrap: wrap;
				}
				.el-radio {
					margin-top: 10px;
					margin-right: 0;
					width: 40%;
					display: flex;
					align-items: flex-start;
					.el-radio__label {
						display: inline-block;
						width: calc(100% - 15px);
						word-break: break-all;
						white-space: normal;
					}
				}
			}
			.tip-wrapper {
				margin-top: 10px;
				display: flex;
				align-items: flex-start;
				font-size: 12px;
				color: #ff9d57;
				line-height: 16px;
				.content {
					flex: 1;
				}
			}
			.el-form-item__label {
				padding: 0;
				height: 21px;
				font-size: 14px;
				font-weight: 600;
				font-family: SourceHanSansCN-Medium, SourceHanSansCN;
				color: #222222;
				line-height: 21px;
			}
			.el-form-item__content {
				margin-top: 10px;
				.el-select {
					width: 100%;
				}
				.el-date-editor.el-input,
				.el-date-editor.el-input__inner {
					width: 100%;
				}
			}
			.el-input__count {
				bottom: 0;
				right: 6px;
				background: transparent;
			}
		}
	}
}
