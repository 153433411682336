@import "~@/styles/variables/variables.scss";
























































































































































































































































































































.batch {
	@include base-button($width: 120px);
}
.one {
	@include base-button($width: 100px);
	.one-send {
		margin-right: 20px;
	}
}
::v-deep {
	.el-dialog__body {
		padding: 0 0 15px 0 !important;
	}
	.gc-blank-page {
		margin-top: 102px !important;
	}
}
.meter-type-search {
	padding: 0 24px;
}
.command-box {
	min-height: 260px;
}
.command-wrapper {
	box-sizing: border-box;
	padding-top: 20px;
	height: 363px;
	display: flex;
	.row-block {
		height: 100%;
		border-right: 1px dashed #c4cde6;
		overflow-y: auto;
		.title-pale {
			padding: 10px 0 20px 24px;
			font-size: 12px;
			color: #999999;
			line-height: 18px;
		}
		.menu-item {
			padding-left: 23px;
			line-height: 34px;
			background: white;
			font-size: 14px;
			font-weight: 400;
			color: #4a4a4a;
			transition: all ease-in-out 0.2s;
			cursor: pointer;
			&.active {
				color: $base-color-primary;
				background: #e9f2fe;
			}
		}
	}
	.command-sort {
		flex: 0 0 140px;
	}
	.command-list {
		flex: 0 0 219px;
	}
	.command-content {
		flex: 1;
		height: 100%;
		width: 339px;
	}
}
.footer {
	@include base-button($height: 32px, $radius: 16px);
	.loading-width {
		width: 100px !important;
	}
}
.item-box {
	.item-box-title {
		width: 100%;
		display: inline-block;
		padding: 0 24px;
		margin-bottom: 12px;
		color: #ababab;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			left: 90px;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			border-bottom: 1px solid #eef0f3;
		}
	}
	.other-params {
		padding: 0 24px;
		.gc-custom-search {
			width: 100% !important;
			& + .gc-custom-search {
				margin-top: 12px;
			}
		}
	}
	.item-box-content {
		padding-bottom: 12px;
	}
}
